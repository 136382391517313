import { useState } from "react";
import Base from "./Base";
import { useParams } from "react-router-dom";
import { useFetch, useDocumentTitle } from "../hooks";
import FinishedEditingLink from "../components/FinishedEditingLink";
import FilesetDetailsForm from "../components/FilesetDetailsForm";
import FilesetDataEditor from "../components/FilesetDataEditor";
import FilesetOrganismEditor from "../components/FilesetOrganismEditor";

const EditFilesetPage = () => {

  const { id } = useParams();

  const [fileset, setFileset] = useState(null);

  const { loading, status } = useFetch(`/filesets/${id}/edit`, {
    onCompleted: data => setFileset(data)
  });

  const { loading: organismsLoading, data: organisms } = useFetch("/organisms");

  useDocumentTitle(fileset ? `Edit ${fileset.name} - Flow` : "Flow");

  if (loading || organismsLoading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  return (
    <Base>
      <div className="flex flex-wrap items-start break-all justify-between gap-x-4 gap-y-1 mb-4 md:mb-10">
        <h1 className="mb-0">{fileset.name}</h1>
        <FinishedEditingLink />
      </div>
      <FilesetDetailsForm fileset={fileset} setFileset={setFileset} />
      <FilesetDataEditor fileset={fileset} setFileset={setFileset} className="mt-12" />
      <FilesetOrganismEditor
        fileset={fileset}
        setFileset={setFileset}
        organisms={organisms}
        className="mt-12"
      />
    </Base>
  );
};

EditFilesetPage.propTypes = {
  
};

export default EditFilesetPage;