import React from "react";
import PropTypes from "prop-types";
import { useLazyFetch } from "../hooks";
import Modal from "./Modal";
import Button from "./Button";

const MetadataAttributeDeletionModal = props => {

  const { setShow, metadataAttribute, setSampleType } = props;

  const [{ loading }, deleteMetadataAttribute] = useLazyFetch(`/samples/metadata/${metadataAttribute.id}/delete`, {
    method: "POST",
    onCompleted: data => {
      setSampleType(data);
      setShow(false);
    }
  })

  const text = `This will permanently delete the metadata attribute ${metadataAttribute.name}.`
  
  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <Modal setShowModal={setShow} title={"Remove Metadata Attribute?"} text={text} className="max-w-lg">
      <div className="flex gap-3 mx-auto w-fit">
        <Button className={primaryClass} loading={loading} onClick={deleteMetadataAttribute}>Delete</Button>
        <button className={secondaryClass} onClick={() => setShow(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

MetadataAttributeDeletionModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  metadataAttribute: PropTypes.object.isRequired,
  setSampleType: PropTypes.func.isRequired,
};

export default MetadataAttributeDeletionModal;