import React from "react";
import PropTypes from "prop-types";
import { useLazyFetch } from "../hooks";
import Modal from "./Modal";
import Button from "./Button";

const DataTypeDeletionModal = props => {

  const { setShow, dataType, dataTypes, setDataTypes } = props;

  const [{ loading }, deleteDataType] = useLazyFetch(`/data/types/${dataType.id}/delete`, {
    method: "POST",
    onCompleted: () => {
      setDataTypes(dataTypes.filter(t => t.id !== dataType.id));
      setShow(false);
    }
  })

  const text = `This will permanently delete the data type ${dataType.name}. Any data of this type will then have no data type.`
  
  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";


  return (
    <Modal setShowModal={setShow} title={"Remove Data Type?"} text={text} className="max-w-lg">
      <div className="flex gap-3 mx-auto w-fit">
        <Button className={primaryClass} loading={loading} onClick={deleteDataType}>Delete</Button>
        <button className={secondaryClass} onClick={() => setShow(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

DataTypeDeletionModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  dataType: PropTypes.object.isRequired,
  dataTypes: PropTypes.array.isRequired,
  setDataTypes: PropTypes.func.isRequired,
};

export default DataTypeDeletionModal;