import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "./Modal";
import { DELETE_ORGANISM } from "../mutations";
import { useMutation } from "@apollo/client";
import Button from "./Button";
import { ORGANISMS_ADMIN } from "../queries";

const DeleteOrganism = props => {

  const { organism } = props;

  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState(null);

  const [deleteOrganism, deleteOrganismMutation] = useMutation(DELETE_ORGANISM, {
    variables: {id: organism.id},
    refetchQueries: [{query: ORGANISMS_ADMIN}],
    awaitRefetchQueries: true,
    onCompleted: () => setShowModal(false),
    onError: () => setError("There was a problem deleting the organism.")
  });

  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  const text = `There are no genomes for this organism.`

  return (
    <div className="font-normal text-base inline-block relative bottom-4 ml-2">
      <button
        className="text-sm text-red-600 inline-block"
        onClick={() => setShowModal(true)}
      >
        Delete
      </button>
      {showModal && (
        <Modal setShowModal={setShowModal} title={`Delete ${organism.name}?`} text={text}>
          {error && <div className="text-red-900 text-center text-sm mb-4">{error}</div>}
          <div className="flex gap-3 mx-auto w-fit mt-4">
            <Button className={primaryClass} loading={deleteOrganismMutation.loading} onClick={deleteOrganism}>Delete</Button>
            <button className={secondaryClass} onClick={() => setShowModal(false)}>Go Back</button>
          </div>
        </Modal>
      )}
    </div>
  );
};

DeleteOrganism.propTypes = {
  organism: PropTypes.object.isRequired,
};

export default DeleteOrganism;