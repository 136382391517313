import { useState } from "react";
import { useParams } from "react-router-dom";
import Base from "./Base";
import { useFetch, useDocumentTitle } from "../hooks";
import FilesetFiles from "../components/FilesetFiles";
import ObjectAttributes from "../components/ObjectAttributes";
import ObjectTitle2 from "../components/ObjectTitle2";
import DataType from "../components/DataType";
import Tabs from "../components/Tabs";
import ExecutionTable2 from "../components/ExecutionsTable2";
import { ClipLoader } from "react-spinners";

const FilesetPage = () => {

  const { id } = useParams();

  const [tab, setTab] = useState("Preparations");
  const [preparationsPage, setPreparationsPage] = useState(1);
  const [preparationsCount, setPreparationsCount] = useState(null);
  const [preparations, setPreparations] = useState(null);
  const [preparationsFilter, setPreparationsFilter] = useState(null);
  const [hasPreparations, setHasPreparations] = useState(null);
  const preparationsPageSize = 25;

  const [analysisPage, setAnalysisPage] = useState(1);
  const [analysisCount, setAnalysisCount] = useState(null);
  const [analysis, setAnalysis] = useState(null);
  const [analysisFilter, setAnalysisFilter] = useState(null);
  const [hasAnalysis, setHasAnalysis] = useState(null);
  const analysisPageSize = 25;

  console.log(preparations)

  const { loading, status, data } = useFetch(`/filesets/${id}`);

  const { loading: preparationsLoading } = useFetch(`/filesets/${id}/executions`, {
    params: {page: preparationsPage, count: preparationsPageSize, filter: preparationsFilter, preparations: true},
    onCompleted: data => {
      setPreparationsCount(data.count);
      setPreparations(data.executions);
      if (hasPreparations === null) setHasPreparations(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / preparationsPageSize);
      if (preparationsPage > maxPossiblePage) setPreparationsPage(maxPossiblePage || 1);
    }
  })

  const { loading: analysisLoading } = useFetch(`/filesets/${id}/executions`, {
    params: {page: analysisPage, count: analysisPageSize, filter: analysisFilter, preparations: false},
    onCompleted: data => {
      setAnalysisCount(data.count);
      setAnalysis(data.executions);
      if (hasAnalysis === null) setHasAnalysis(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / analysisPageSize);
      if (analysisPage > maxPossiblePage) setAnalysisPage(maxPossiblePage || 1);
    }
  })

  useDocumentTitle(data ? `${data.id} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (status === 404) return <Base notFound={true} />

  const isMultiplexed = data.data.length > 0 && !data.data.some(d => d.category !== 3);
  const cornerClassName = "bg-[#125F85] text-white text-xs px-3 flex h-6 whitespace-nowrap items-center rounded lg:px-4 lg:text-sm lg:h-7"
  const corner = <div className={cornerClassName}>Multiplexed Fileset</div>

  const showTabs = hasPreparations || hasAnalysis;

  return (
    <Base>
      <ObjectTitle2
        title={data.organism ? `${data.name} (${data.organism.name})` : data.name}
        type="fileset"
        className="mb-4"
        editUrl={data.can_edit && `/filesets/${id}/edit`}
        corner={isMultiplexed ? corner: null}
      />
      <ObjectAttributes object={data} className="mb-4" />
      <FilesetFiles fileset={data} className="mb-8" />
      {showTabs && <Tabs labels={["Preparations", "Analysis"]} selected={tab} setSelected={setTab} />}
      {tab === "Preparations" && showTabs && (
        <>
          {!preparationsLoading && !hasPreparations && (
            <div className="text-sm mb-6 info sm:mb-8">
              This genome has no genome preps yet.
            </div>
          )}
          {preparationsLoading && !hasPreparations && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasPreparations && (
            <ExecutionTable2
              executions={preparations}
              page={preparationsPage}
              pageSize={preparationsPageSize}
              setPage={setPreparationsPage}
              totalCount={preparationsCount}
              loading={preparationsLoading}
              onFilter={s => setPreparationsFilter(s || null)}
              noMessage="No genome preps match that term." 
            />
          )}
        </>
      )}
      {tab === "Analysis" && showTabs && (
        <>
          {!analysisLoading && !hasAnalysis && (
            <div className="text-sm mb-6 info sm:mb-8">
              This genome has no analysis yet.
            </div>
          )}
          {analysisLoading && !hasAnalysis && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasAnalysis && (
            <ExecutionTable2
              executions={analysis}
              page={analysisPage}
              pageSize={analysisPageSize}
              setPage={setAnalysisPage}
              totalCount={analysisCount}
              loading={analysisLoading}
              onFilter={s => setAnalysisFilter(s || null)}
              noMessage="No analyses match that term." 
            />
          )}
        </>
      )}
    </Base>
  );
};

FilesetPage.propTypes = {
  
};

export default FilesetPage;