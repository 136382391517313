import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";
import Fileset from "../components/Fileset";

const OrganismsPage = () => {

  useDocumentTitle("Organisms - Flow");

  const { data: organisms, loading } = useFetch("/organisms");

  if (loading) return <Base loading={true} />

  return (
    <Base>
      <h1>Organisms</h1>
      <div className="gap-4 flex flex-wrap md:gap-8">
        {organisms.filter(o => o.latest_fileset).map(organism => (
          <Fileset
            fileset={organism.latest_fileset} organism={organism} key={organism.id}
            className="w-full max-w-lg"
          />
        ))}
      </div>
    </Base>
  );
};

OrganismsPage.propTypes = {
  
};

export default OrganismsPage;