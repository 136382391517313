import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fileSize } from "../utils";

const FilesetFiles = props => {

  const { fileset } = props;

  const linkClass = "block text-[#3B59C3] font-medium break-all";
  const sizeClass = "text-[#37474F]";

  return (
    <div className={`bg-[#F9F9F9] rounded-md text-sm w-fit p-3 flex flex-col gap-x-3 gap-y-1.5 sm:p-4 sm:text-base ${props.className || ""}`}>
      {fileset.data.map(d => (
        <Link to={`/data/${d.id}/`} className={linkClass} key={d.id}>
          {d.filename} <span className={sizeClass}>{fileSize(d.size)}</span>
        </Link>
      ))}
    </div>
  );
};

FilesetFiles.propTypes = {
  fileset: PropTypes.object.isRequired
};

export default FilesetFiles;