import { useState, useRef } from "react";
import PropTypes from "prop-types";
import FileInput from "./FileInput";
import { useLazyFetch } from "../hooks";
import { upload } from "../upload";
import UploadButton from "./UploadButton";
import AnnotationErrorModal from "./AnnotationErrorModal";
import AnnotationSheetLinks from "./AnnotationSheetLinks";
import AnnotationWarningModal from "./AnnotationWarningModal";

const AnnotationUploader = props => {

  const { sampleTypes } = props;

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(null);
  const [dataIds, setDataIds] = useState({});
  const [error, setError] = useState("");
  const [annotationErrors, setAnnotationErrors] = useState(null);
  const [annotationWarnings, setAnnotationWarnings] = useState(null);
  const canceled = useRef(false);

  const [,uploadData] = useLazyFetch("/upload/annotation", {method: "POST", form: true});

  const sendChunk = async (filename, chunk, expectedFileSize, isLast, previous, extraParams) => {
    const params = {
      blob: chunk, is_last: isLast, expected_file_size: expectedFileSize,
      data: previous?.data?.id || "", filename, ...extraParams
    }
    return await uploadData({params});
  }

  const onNetworkError = () => {
    setError("There was a network error while uploading the data.");
  }

  const onError = error => {
    if (error.validation) {
      setAnnotationErrors(error.validation);
    } else if (error.warnings) {
      setAnnotationWarnings(error.warnings);
    } else {
      setError("There was a problem uploading the annotation.");
    }
  }

  const upload_ = async (e, ignoreWarnings) => {
    if (ignoreWarnings) {
      return upload(files, 1_000_000, setProgress, setDataIds, canceled, sendChunk, onNetworkError, onError, {ignore_warnings: true});
    } else {
      return upload(files, 1_000_000, setProgress, setDataIds, canceled, sendChunk, onNetworkError, onError);
    }
  }

  const cancel = () => {
    setError("");
    setProgress(null);
    setDataIds({});
    setFiles([]);
    canceled.current = true;
  }

  const canCancel = progress && Object.values(progress).length > 0 && !error && Object.values(progress).some(p => p < 1);

  return (
    <div>
      <div className="text-[#515255] text-xl font-medium mb-3">Upload Annotation</div>
      <FileInput
        files={files}
        setFiles={setFiles}
        multiple={false}
        accept=".csv,.xlsx"
        allowDirectories={false}
        progress={progress}
        setProgress={setProgress}
        dataIds={dataIds}
        setDataIds={setDataIds}
        error={error}
        setError={setError}
      />
      <UploadButton files={files} onClick={upload_} cancel={canCancel ? cancel : null} />
      <AnnotationSheetLinks className="mt-4" sampleTypes={sampleTypes} />
      {annotationErrors && (
        <AnnotationErrorModal errors={annotationErrors} setShowModal={setAnnotationErrors} />
      )}
      {annotationWarnings && (
        <AnnotationWarningModal
          warnings={annotationWarnings} setShowModal={setAnnotationWarnings}
          upload={upload_}
        />
      )}
    </div>
  );
};

AnnotationUploader.propTypes = {
  sampleTypes: PropTypes.array.isRequired,
};

export default AnnotationUploader;