import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import { useNavigate } from "react-router-dom";
import { formatTimestamp } from "../utils";

const SampleRow = props => {

  const { sample, selectedIds, setSelectedIds } = props;

  const navigate = useNavigate();

  const rowClick = () => navigate(`/samples/${sample.id}/`);

  const boxClick = e => {
    e.stopPropagation();
    if (selectedIds.includes(sample.id)) {
      setSelectedIds(selectedIds.filter(id => id !== sample.id))
    } else {
      setSelectedIds([...selectedIds, sample.id])
    }
  }

  const cellClass = "px-4 py-3 whitespace-nowrap text-xs cursor-pointer";

  return (
    <tr onClick={rowClick} className={`${props.className || ""} bg-opacity-80 hover:bg-opacity-100`}>
      <td className={cellClass} onClick={boxClick}>
        <Checkbox checked={selectedIds.includes(sample.id)} onChange={() => {}} />
      </td>
      <td className={`${cellClass} w-full`}>{sample.name}</td>
      <td className={cellClass}>{sample.organism?.name || "Other"}</td>
      <td className={cellClass}>{sample.sampleType || "Generic"}</td>
      <td className={cellClass}>{formatTimestamp(sample.created)}</td>
      <td className={cellClass}>{sample.owner?.name}</td>
    </tr>
  );
};

SampleRow.propTypes = {
  sample: PropTypes.object.isRequired,
  selectedIds: PropTypes.array.isRequired,
  setSelectedIds: PropTypes.func.isRequired,
};

export default SampleRow;