import React, { useState } from "react";
import PropTypes from "prop-types";
import { ReactComponent as LeftArrow } from "../images/left-arrow.svg";
import { ReactComponent as GitIcon } from "../images/git.svg";
import { ReactComponent as SyncIcon } from "../images/sync.svg";
import { ReactComponent as CheckoutIcon } from "../images/checkout.svg";
import PipelineAdmin from "../components/PipelineAdmin";
import RepoPullModal from "./RepoPullModal";
import DeletePipelineRepo from "./DeletePipelineRepo";
import PiplineRepoForm from "./PipelineRepoForm";
import PipelineForm from "./PipelineForm";
import RepoCheckoutModal from "./RepoCheckoutModal";

const PipelineRepoAdmin = props => {

  const { repo, subcategories, upstreamPipelineVersions } = props;

  const [showPullModal, setShowPullModal] = useState(false);
  const [showCheckoutModal, setShowCheckoutModal] = useState(false);
  const [editing, setEditing] = useState(false);
  const [showNewPipeline, setShowNewPipeline] = useState(false);

  const executionCount = repo.pipelines.reduce((acc1, pipeline) => {
    return acc1 + pipeline.versions.reduce((acc2, version) => {
      return acc2 + version.executionCount;
    }, 0);
  }, 0);

  if (editing) return <PiplineRepoForm repo={repo} setShow={setEditing} />;

  return (
    <div className="border-b pt-8 pb-10">
      <div className="font-medium text-2xl mb-1">
        <GitIcon className="inline-block w-6 h-auto mr-2" />
        {repo.path}
        <SyncIcon
          className="inline-block w-4 h-auto ml-2 relative bottom-4 fill-[#3B59C3] cursor-pointer z-10"
          onClick={() => setShowPullModal(true)}
        />
        <CheckoutIcon
          className="inline-block w-6 h-auto ml-2 relative bottom-4 fill-[#3B59C3] cursor-pointer z-10"
          onClick={() => setShowCheckoutModal(true)}
        />
        <div
          className="link font-normal text-sm inline-block relative bottom-4 ml-2 text-[#3B59C3] link hover:underline"
          onClick={() => setEditing(true)}
        >
          Edit
        </div>
      </div>
      <div className="mb-6">
        <a className="text-[#3B59C3]" href={repo.url}>
          {repo.url.split("/").slice(-2).join("/")}
        </a>
        {repo.originalUrl && (
          <>
            <LeftArrow className="inline-block w-3 mx-1" />
            <a className="text-[#3B59C3]" href={repo.originalUrl}>
              {repo.originalUrl.split("/").slice(-2).join("/")}
            </a>
          </>
        )}
      </div>
      <div className="flex flex-wrap gap-4">
        {repo.pipelines.map((pipeline, index) => (
          <PipelineAdmin
            key={pipeline.id} pipeline={pipeline} subcategories={subcategories}
            upstreamPipelineVersions={upstreamPipelineVersions}
            repoId={repo.id}
            isFirst={index === 0}
            isLast={index === repo.pipelines.length - 1}
          />
        ))}
        <div className="w-48">
          {showNewPipeline && <PipelineForm setShow={setShowNewPipeline} subcategories={subcategories} repoId={repo.id} />}
          {!showNewPipeline && (
            <button className="btn-primary w-full" onClick={() => setShowNewPipeline(true)}>
              Add New Pipeline
            </button>
          )}
        </div>
      </div>
      {executionCount === 0 && <DeletePipelineRepo repo={repo} />}
      {showPullModal && <RepoPullModal repo={repo} setShow={setShowPullModal} />}
      {showCheckoutModal && <RepoCheckoutModal repo={repo} setShow={setShowCheckoutModal} />}

    </div>
  );
};

PipelineRepoAdmin.propTypes = {
  repo: PropTypes.object.isRequired,
  subcategories: PropTypes.array.isRequired,
  upstreamPipelineVersions: PropTypes.array.isRequired
};

export default PipelineRepoAdmin;