import React from "react";
import PropTypes from "prop-types";
import { useLazyFetch } from "../hooks";
import Modal from "./Modal";
import Button from "./Button";

const SampleTypeDeletionModal = props => {

  const { setShow, sampleType, sampleTypes, setSampleTypes } = props;

  const [{ loading }, deleteSampleType] = useLazyFetch(`/samples/types/${sampleType.id}/delete`, {
    method: "POST",
    onCompleted: () => {
      setSampleTypes(sampleTypes.filter(t => t.id !== sampleType.id));
      setShow(false);
    }
  })

  const text = `This will permanently delete the sample type ${sampleType.name}. Any samples of this type will then have no sample type.`
  
  const primaryClass = "btn-primary bg-red-800 border-red-800 hover:bg-red-900 hover:border-red-900";
  const secondaryClass = "btn-secondary text-red-900 border-red-800 cursor-pointer hover:bg-red-800 hover:border-red-800";

  return (
    <Modal setShowModal={setShow} title={"Remove Sample Type?"} text={text} className="max-w-lg">
      <div className="flex gap-3 mx-auto w-fit">
        <Button className={primaryClass} loading={loading} onClick={deleteSampleType}>Delete</Button>
        <button className={secondaryClass} onClick={() => setShow(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

SampleTypeDeletionModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  sampleType: PropTypes.object.isRequired,
  sampleTypes: PropTypes.array.isRequired,
  setSampleTypes: PropTypes.func.isRequired,
};

export default SampleTypeDeletionModal;