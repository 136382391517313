import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { fileSize, formatTimestamp } from "../utils";

const SampleResequencing = props => {

  const { sample } = props;

  return (
    <div className={` ${props.className || ""}`}>
      {sample.filesets.map((fileset, index) => (
        <React.Fragment key={index}>
          {index !== 0 && (
            <div className="w-52 flex opacity-0 justify-center -mt-px -mb-px relative z-40 md:opacity-100">
              <div className="w-4 h-12 bg-[#F9F9F9] border-x border-[#EFEFEF]" />
            </div>
          )}
          <div className="flex flex-col items-center gap-2 md:flex-row md:gap-4">
            <Link to={`/filesets/${fileset.id}`} className="bg-[#F9F9F9] p-2 w-full text-center border border-[#EFEFEF] rounded flex-shrink-0 hover:bg-gray-100 md:w-52">
              <div className="text-sm text-[#3B59C3] font-medium">{index === 0 ? "Initialised" : "Resequenced"}</div>
              <div className="font-medium">{formatTimestamp(fileset.created)}</div>
            </Link>
            <div className="flex items-center flex-col gap-1 text-xs text-[#3B59C3] font-medium lg:text-sm">
              {fileset.data.map(data => (
                <Link to={`/data/${data.id}/`} key={data.id} className="">
                  <span className="break-all">{data.filename}</span> <span className="text-[#37474F]">{fileSize(data.size)}</span>
                </Link>
              ))}
            </div>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

SampleResequencing.propTypes = {
  sample: PropTypes.object.isRequired
};

export default SampleResequencing;