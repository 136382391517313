import { useState } from "react";
import PropTypes from "prop-types";
import SampleSelector2 from "./SampleSelector2";
import Selector from "./Selector";
import FilesetSelector from "./FilesetSelector";
import { ReactComponent as PlusIcon } from "../images/plus.svg";
import DataSelector2 from "./DataSelector2";

const CsvField = props => {

  const { schema, data, setData, organism, setOrganism, inputClass, placeholderClass, selectOptionClass, selectOptionsClass } = props;

  const [extraColumns, setExtraColumns] = useState([]);

  const columnsToDisplay = schema.columns.filter(c => c.render);

  const pairedOptions = [
    {id: "both", label: "Both reads"},
    {id: "first", label: "read1 only"},
    {id: "second", label: "read2 only"},
  ]

  const sampleSelected = (sample, rowIndex) => {
    if (sample === null) {
      data.rows = data.rows.filter((_, i) => i !== rowIndex);
      setData({...data, rows: [...data.rows]});
    } else {
      data.rows.push({sample: sample, values: columnsToDisplay.reduce(
        (p, c) => ({...p, [c.name]: sample[c.from_sample] || sample.type_specific_metadata[c.from_sample] || ""}), {}
      )});
      setData({...data, rows: [...data.rows], complete: columnsToDisplay.length === 0});
      setOrganism(sample.organism);
    }
  }

  const filesetSelected = (fileset, rowIndex) => {
    if (fileset === null) {
      data.rows = data.rows.filter((_, i) => i !== rowIndex);
      setData({...data, rows: [...data.rows]});
    } else {
      data.rows.push({fileset: fileset, values: columnsToDisplay.reduce(
        (p, c) => ({...p, [c.name]: ""}), {}
      )});
      setData({...data, rows: [...data.rows], complete: columnsToDisplay.length === 0});
      setOrganism(fileset.organism);
    }
  }

  const addNewRow = () => {
    data.rows.push({values: columnsToDisplay.reduce(
      (p, c) => ({...p, [c.name]: ""}), {}
    )});
    setData({...data, rows: [...data.rows]});
  }

  const requiredHeaders = columnsToDisplay.filter(
    col => col.required
  ).map(col => col.name);

  const rowsMissingData = data.rows.filter(row => {
    for (let header of requiredHeaders) {
      if (!row.values[header]) return true;
    }
    return false;
  });

  const plusClicked = () => {
    setExtraColumns([...extraColumns, ""]);
  }

  const crossClicked = index => {
    data.rows.forEach(row => {
      delete row.values[extraColumns[index]];
    })
    setData({...data, rows: [...data.rows]});
    setExtraColumns(extraColumns.filter((_, i) => i !== index));
  }

  const columnNameUpdated = (index, name) => {
    data.rows.forEach(row => {
      row.values[name] = row.values[extraColumns[index]];
      delete row.values[extraColumns[index]];
    })
    setExtraColumns(extraColumns.map((c, i) => i === index ? name : c));
    setData({...data, rows: [...data.rows]});
  }

  const removeRow = index => {
    setData({...data, rows: data.rows.filter((_, i) => i !== index)});
    if (data.rows.length === 1) {
      setOrganism(null);
    }
  }

  const usesPaths = schema.columns.some(
    c => Number.isInteger(c.from_sample)
  );
  const hasPaired = data.rows.some(r => r.sample?.is_paired);
  const askAboutPairs = schema.takes_samples && usesPaths && hasPaired;

  const headerClass = "py-1 px-3 font-medium text-left border-r last:border-r-0";
  const cellClass = "bg-transparent font-normal text-sm w-full h-full min-h-[28px] rounded-none px-3";

  return (
    <div className="relative">
      
      <div className="absolute -left-3.5 top-11 xl:-left-4.5">
        {data.rows.map((row, rowIndex) => (
          <div className="h-8">
            <PlusIcon
              onClick={() => removeRow(rowIndex)}
              className="w-3 h-3 cursor-pointer fill-gray-400 rotate-45 hover:fill-gray-600 xl:w-3.5 xl:h-3.5" />
          </div>
        ))}
      </div>
      <div className={`overflow-x-auto ${inputClass} pt-0 pb-0 pl-0 pr-0`}>
        <table className="min-w-full">
          <thead className="border-b border-gray-300">
            <tr>
              {schema.takes_samples && (
                <th className={`${headerClass} w-full max-w-7xl`}>sample</th>
              )}
              {schema.takes_filesets && (
                <th className={`${headerClass} w-full max-w-7xl`}>fileset</th>
              )}
              {columnsToDisplay.map((column, index) => (
                <th key={index} className="py-1 px-3 font-medium text-left border-r last:border-r-0">
                  {column.name}
                  {column.required && <span className="text-red-800">*</span>}
                </th>
              ))}
              {extraColumns.map((_, index) => (
                <th key={index} className="relative py-1 px-3 pr-3.5 font-medium text-left border-r last:border-r-0">
                  <div
                    contentEditable
                    ignoreContentEditableWarning
                    className="min-w-[20px] outline-none whitespace-nowrap"
                    onInput={e => columnNameUpdated(index, e.currentTarget.textContent)}
                  />
                    
                  <PlusIcon
                    className="absolute right-px top-px w-3 h-3 cursor-pointer fill-red-600 rotate-45"
                    onClick={() => crossClicked(index)}
                  />
                </th>
              ))}
              {schema.allow_custom_columns && (
                <th className="px-2 cursor-pointer hover:bg-slate-100" onClick={plusClicked}>
                  +
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {data.rows.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b">
                {row.sample && (
                  <SampleSelector2
                    inputClass={cellClass}
                    organismId={organism?.id}
                    sample={row.sample}
                    setSample={sample => sampleSelected(sample, rowIndex)}
                    fullMetadata={true}
                    sampleTypes={schema.sample_types}
                  />
                )}
                {row.fileset && (
                  <FilesetSelector
                    inputClass={cellClass}
                    fileset={row.fileset}
                    setFileset={fileset => filesetSelected(fileset, rowIndex)}
                    organismId={organism?.id}
                    dataCount={schema.fileset_size}
                    dataCategory={schema.fileset_category}
                  />
                )}
                {columnsToDisplay.map((column, index) => (
                  <td className="h-6 border-l" key={index}>
                    {column.type === "data" ? (
                      <DataSelector2
                        inputClass={cellClass}
                        data={row.values[column.name]}
                        setData={d => setData({...data, rows: data.rows.map((r, i) => i === rowIndex ? {
                          ...r, values: {...r.values, [column.name]: d}
                        } : r)})}
                        organismId={organism?.id}
                        dataTypes={column.data_types}
                        dataCategory={column.data_category}
                        dataCount={column.data_size}
                        placeholder={column.data_category === 3 ? "Select multiplexed reads files" : "Select data"}
                        placeholderClass={placeholderClass}
                      />
                    ) : (
                      <input
                        className={cellClass}
                        value={row.values[column.name]}
                        onChange={e => setData({...data, rows: data.rows.map((r, i) => i === rowIndex ? {
                          ...r, values: {...r.values, [column.name]: e.target.value}
                        } : r)})}
                      />
                    )}
                  </td>
                ))}
                {extraColumns.map((name, index) => (
                  <td key={index} className="h-6 border-l">
                    <input
                      className={cellClass}
                      value={row.values[name]}
                      onChange={e => setData({...data, rows: data.rows.map((r, i) => i === rowIndex ? {
                        ...r, values: {...r.values, [name]: e.target.value}
                      } : r)})}
                    />
                  </td>
                ))}
                {schema.allow_custom_columns && (
                  <td className="h-6 border-l px-4">
                    
                  </td>
                )}
              </tr>
            ))}
            <tr>
              {schema.takes_samples && (
                <td>
                  <SampleSelector2
                    inputClass={cellClass}
                    sample={null}
                    setSample={sampleSelected}
                    fullMetadata={true}
                    sampleTypes={schema.sample_types}
                    organismId={organism?.id}
                    placeholder={organism ? `Select a ${organism.name} sample` : "Select a sample"}
                    placeholderClass={placeholderClass}
                  />
                </td>
              )}
              {schema.takes_filesets && (
                <td>
                  <FilesetSelector
                    inputClass={cellClass}
                    fileset={null}
                    setFileset={filesetSelected}
                    organismId={organism?.id}
                    dataCount={schema.fileset_size}
                    dataCategory={schema.fileset_category}
                    placeholder={schema.fileset_category === 3 ? "Select multiplexed reads files" : organism ? `Select a ${organism.name} fileset` : "Select a fileset"}
                    placeholderClass={placeholderClass}
                  />
                </td>
              )}
              {!schema.takes_samples && !schema.takes_filesets && (
                <td className="h-8">
                  <PlusIcon
                    onClick={addNewRow}
                    className="w-5 h-5 ml-2 p-0.5 cursor-pointer fill-[#A5ACC6] border-2 border-[#A5ACC6] rounded-full "
                  />
                </td>
              )}
              {(schema.takes_samples || schema.takes_filesets) && (
                columnsToDisplay.map((col) => <td className="h-6 border-l" key={col.name} />)
              )}
              {extraColumns.map((_, index) => (
                <td key={index} className="h-6 border-l px-4" />
              ))}
              {schema.allow_custom_columns && <td className="h-6 border-l px-4" />}
            </tr>
          </tbody>
        </table>
      </div>
      {askAboutPairs && (
        <div className="mb-4">
          <div className="text-xs mb-1 sm:text-sm">
            You've selected at least one paired-end sample - which reads should be used?
          </div>
          <Selector
            value={data.paired}
            options={pairedOptions}
            onChange={mode => setData({...data, paired: mode})}
            inputClassName="bg-[#F9F9F9] border border-[#A5ACC6] rounded w-32 py-1 text-[#54618D] font-medium px-2 text-xs md:text-sm cursor-pointer"
            optionClassName={selectOptionClass.replace(/h-\d/g, "h-7")}
            optionsClassName={`${selectOptionsClass.replace("w-full", "w-32").replace(/-mt-\d/g, "-mt-px")} text-xs md:text-sm`}
          />
        </div>
      )}
      {rowsMissingData.length > 0 && (
        <div className="text-red-600 text-xs -mt-1 mb-2">
          {rowsMissingData.length} sample{rowsMissingData.length > 1 ? "s are" : " is"} missing data in columns marked as required.
          Pipeline may not run correctly.
        </div>
      )}
    </div>
  );
};

CsvField.propTypes = {
  schema: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
  organism: PropTypes.object,
  setOrganism: PropTypes.func,
  inputClass: PropTypes.string,
  placeholderClass: PropTypes.string,
  selectOptionClass: PropTypes.string,
  selectOptionsClass: PropTypes.string,
};

export default CsvField;