import { useState } from "react";
import PropTypes from "prop-types";
import { useLazyFetch } from "../hooks";
import Modal from "./Modal";
import Button from "./Button";

const SampleTypeEditModal = props => {

  const { setShow, sampleType, sampleTypes, setSampleTypes } = props;

  const [name, setName] = useState(null);
  const [description, setDescription] = useState(null);
  const [errorFields, setErrorFields] = useState([]);

  const displayName = name === null ? sampleType.name : name;
  const displayDescription = description === null ? sampleType.description : description;

  const [{ loading }, updateSampleType] = useLazyFetch(`/samples/types/${sampleType.id}/edit`, {
    method: "POST",
    params: { name: displayName, description: displayDescription },
    onCompleted: data => {
      setErrorFields([]);
      setSampleTypes(sampleTypes.map(t => t.id === sampleType.id ? data : t));
      setShow(false);
    },
    onError: error => {
      setErrorFields(Object.keys(error.error || {}));
    }
  })

  
  const canSave = !loading && displayName && displayDescription && (name !== null || description !== null);
  const primaryClass = `btn-primary ${canSave ? "" : "pointer-events-none opacity-40"}`;
  const secondaryClass = "btn-secondary";
  const inputClass = "bg-[#F3F3F3] rounded mb-1.5 w-full h-8 text-[#3B59C3] font-medium px-2";


  return (
    <Modal setShowModal={setShow} title={"Update Data Type"} className="max-w-lg w-full">
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex flex-col gap-1">
            <label className="text-sm">Name</label>
            <input
              type="text"
              className={`${inputClass} ${errorFields.includes("name") ? "border-red-500 bg-red-100" : ""}`}
              value={displayName}
              onChange={e => setName(e.target.value)}
            />
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-sm">Description</label>
            <input
              className={`${inputClass} text-sm ${errorFields.includes("description") ? "border-red-500 bg-red-100" : ""}`}
              value={displayDescription}
              onChange={e => setDescription(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="flex gap-3 mt-3 w-fit">
        <Button className={primaryClass} loading={loading} onClick={updateSampleType}>Save</Button>
        <button className={secondaryClass} onClick={() => setShow(false)}>Go Back</button>
      </div>
    </Modal>
  );
};

SampleTypeEditModal.propTypes = {
  setShow: PropTypes.func.isRequired,
  sampleType: PropTypes.object.isRequired,
  sampleTypes: PropTypes.array.isRequired,
  setSampleTypes: PropTypes.func.isRequired,
};

export default SampleTypeEditModal;