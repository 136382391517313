import React, { useState } from "react";
import Base from "./Base";
import Tabs from "../components/Tabs";
import { useQuery } from "@apollo/client";
import { PIPELINE_ADMIN } from "../queries";
import PipelineRepoAdmin from "../components/PipelineRepoAdmin";
import PipelineRepoForm from "../components/PipelineRepoForm";
import { useDocumentTitle } from "../hooks";
import PipelineCategoriesAdmin from "../components/PipelineCategoriesAdmin";
import PipelineConfigTab from "../components/PipelineConfigTab";
import OrganismsAdmin from "../components/OrganismsAdminTab";
import NewsAdminTab from "../components/NewsAdminTab";
import UsersAdmin from "../components/UsersAdmin";
import TypesAdminTab from "../components/TypesAdminTab";

const AdminPage = () => {

  const tabs = ["Pipelines", "Pipeline Categories", "Pipeline Config", "Types", "Organisms", "Users", "News"];

  const [tab, setTab] = useState(tabs[0]);
  const [showNewRepo, setShowNewRepo] = useState(false);

  useDocumentTitle("Admin Panel - Flow");

  const { data } = useQuery(PIPELINE_ADMIN);

  const repos = data?.pipelineRepos;
  const categories = data?.pipelineCategories;
  if (categories) {
    for (const category of categories) {
      category.pipelineCount = 0;
      for (const subcategory of category.subcategories) {
        subcategory.pipelineCount = 0;
        for (const repo of repos) {
          for (const pipeline of repo.pipelines) {
            if (pipeline.subcategory.id === subcategory.id) {
              subcategory.pipelineCount++;
              category.pipelineCount++;
            }
          }
        }
      }
    }
  }
  const subcategories = categories ? data.pipelineCategories.reduce((acc, category) => {
    return [...acc, ...category.subcategories.map(s => ({...s, category}))];
  }, []) : [];
  const upstreamPipelineVersions = data?.pipelineRepos.reduce((acc1, repo) => {
    return [...acc1, ...repo.pipelines.filter(p => p.preparesGenome).reduce((acc2, pipeline) => {
      return [...acc2, ...pipeline.versions.map(v => ({id: v.id, name: v.name, pipelineName: pipeline.name}))];
    }, [])]
  }, []);

  return (
    <Base isAdmin={true}>
      <h1>Admin Panel</h1>
      <Tabs labels={tabs} selected={tab} setSelected={setTab} />
      {tab === "Pipelines" && repos && (
        <div className="flex flex-col">
          {repos.map(repo => (
            <PipelineRepoAdmin
              key={repo.id} repo={repo} subcategories={subcategories}
              upstreamPipelineVersions={upstreamPipelineVersions}
            />
          ))}
          {showNewRepo && <PipelineRepoForm setShow={setShowNewRepo} />}
          {!showNewRepo && (
            <button className="btn-primary w-fit mt-8" onClick={() => setShowNewRepo(true)}>
              Add New Repo
            </button>
          )}
        </div>
      )}
      {tab === "Pipeline Categories" && categories && (
        <PipelineCategoriesAdmin categories={categories} />
      )}
      {tab === "Pipeline Config" && (
        <PipelineConfigTab />
      )}
      {tab === "Types" && (
        <TypesAdminTab />
      )}
      {tab === "Organisms" && (
        <OrganismsAdmin /> 
      )}
      {tab === "Users" && (
        <UsersAdmin />
      )}
      {tab === "News" && (
        <NewsAdminTab /> 
      )}
    </Base>
  );
};

AdminPage.propTypes = {
  
};

export default AdminPage;