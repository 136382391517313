import { useState } from "react";
import { useQuery } from "@apollo/client";
import { ORGANISMS_ADMIN } from "../queries";
import OrganismForm from "./OrganismForm";
import OrganismAdmin from "./OrganismAdmin";

const OrganismsAdmin = () => {

  const { loading, data } = useQuery(ORGANISMS_ADMIN);

  const [showNewOrganism, setShowNewOrganism] = useState(false);

  return (
    <div>
      {loading && <p>Loading...</p>}
      {data && (
        <div className="flex flex-col gap-8">
          {data.organisms.map(organism => (
            <OrganismAdmin key={organism.id} organism={organism} />
          ))}
          {showNewOrganism && <OrganismForm setShow={setShowNewOrganism} />}
          {!showNewOrganism && (
            <button className="btn-primary w-fit mt-8" onClick={() => setShowNewOrganism(true)}>
              Add New Organism
            </button>
          )}
        </div>
      )}
    </div>
  );
};

OrganismsAdmin.propTypes = {
  
};

export default OrganismsAdmin;