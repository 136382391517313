import { useState } from "react";
import PropTypes from "prop-types";
import DataSelector2 from "../components/DataSelector2";
import Button from "../components/Button";
import { Link } from "react-router-dom";
import { useLazyFetch } from "../hooks";

const PairingEditor = props => {

  const { data, setData } = props;

  const [selectedData, setSelectedData] = useState(null);

  const [{loading}, updatePairing] = useLazyFetch(`/data/${data.id}/edit`, {
    method: "POST",
    onCompleted: data => {
      setData(data);
      setSelectedData(null);
    }
  })

  const infoClass = "info max-w-md pl-2 text-sm";

  return (
    <div className="edit-section">
      <h2 className="edit-heading">Pairing</h2>
      {data.paired && (
        <div className={infoClass}>
          This multiplexed data is currently paired with{" "}
          <Link className="text-[#3B59C3]" to={`/data/${data.paired.id}/`}>{data.paired.filename}</Link>.
          You can change the pairing by selecting a different data file below, or clear the selector below to unpair it.
        </div>
      )}
      {!data.paired && (
        <div className={infoClass}>
          This multiplexed data is not currently paired with any other data. You can pair it with another
          multiplexed data file by selecting one below.
        </div>
      )}
      <div className="flex h-9 mt-2">
        <DataSelector2
          data={selectedData}
          setData={setSelectedData}
          forceIsOwned={true}
          category={3}
          isSingle={false}
          placeholder="No pair"
          className="w-full md:w-fit"
          inputClass="bg-[#F9F9F9] block flex-shrink-0 border border-r-0 flex-grow md:w-96 placeholder-text-[#777F9B] border-[#A5ACC6] rounded-l mb-1.5 py-1.5 h-full text-[#54618D] font-medium px-3 text-sm md:text-base"
        />
        <Button
          className={`btn-primary rounded-l-none ${loading ? "opacity-50 pointer-events-none" : ""}`}
          loading={loading}
          onClick={() => updatePairing({params: {paired: selectedData?.id || null}})}
        >
          Update
        </Button>
      </div>
    </div>
  );
};

PairingEditor.propTypes = {
  data: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired
};

export default PairingEditor;