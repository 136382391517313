import { roundTo } from "round-to";
import moment from "moment";

export const fileSize = bytes => {
  if (!bytes) return "0B";
  if (bytes < 10 ** 3) return `${bytes}B`;
  if (bytes < 10 ** 6) return `${(bytes / 10 ** 3).toPrecision(3)}KB`;
  if (bytes < 10 ** 9) return `${(bytes / 10 ** 6).toPrecision(3)}MB`;
  if (bytes < 10 ** 12) return `${(bytes / 10 ** 9).toPrecision(3)}GB`;
  return `${roundTo(bytes / 10 ** 12, 2)}TB`;
}

export const getProcessName = data => {
  if (data.upstreamProcessExecution) {
    const processName = data.upstreamProcessExecution.processName;
    const sections = processName.split(":");
    return sections[sections.length - 1];
  }
  return "Uploaded"; 
}

export const formatTimestamp = ts => {
  return moment(ts * 1000).format("D MMM YYYY, HH:mm:ss ");
}

export const processName = fullName => {
  return fullName.split(":")[fullName.split(":").length - 1];
}

export const truncateText = (text, maxLength) => {
  if (text.length <= maxLength) return text;
  const words = text.split(" ");
  return words.reduce(
    (p, c) => p.length >= maxLength ? p : `${p} ${c}`, ""
  ) + "...";
}

export const executionToName = execution => {
  if (execution.genome) {
    return (
      `${execution.genome.organism.name} ${execution.genome.name} - ${execution.pipelineVersion.pipeline.name} ${execution.pipelineVersion.name}`
    );
  } else {
    return (
      `${execution.pipelineVersion.pipeline.name} ${execution.pipelineVersion.name}`
    );
  }
}

export const makeParamString = (path, options) => {
  let fullPath = path;
  const params = options.params;
  let paramString = "";
  if (params) {
    paramString = Object.keys(params).filter(key => (params[key] !== null && params[key] !== undefined)).map(
      key => `${key}=${params[key]}`
    ).join("&");
    fullPath = `${path}?${paramString}`;
  }
  return fullPath;
}


export const makeFormRows = (section, mode) => {
  const rows = []; let row = [];
  Object.entries(section.params).forEach(([key, input]) => {
    if (input.type === "hidden") return;
    if (section.modes && input.modes && !input.modes.includes(mode)) return;
    if (["data", "csv"].includes(input.type)) {
      if (row.length) rows.push(row);
      rows.push([[key, input]]);
      row = [];
    } else {
      row.push([key, input]);
    }
  });
  if (row.length) rows.push(row);
  return rows;
}