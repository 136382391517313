import React, { useState } from "react";
import Tabs from "../components/Tabs";
import { useDocumentTitle, useFetch } from "../hooks";
import Base from "./Base";
import DataUploader from "../components/DataUploader";
import MultiplexedUploader from "../components/MultiplexedUploader";
import AnnotationUploader from "../components/AnnotationUploader";
import DemultiplexUploader from "../components/DemultiplexUploader";
import DataImporter from "../components/DataImporter";

const UploadPage = () => {

  const [tab, setTab] = useState(null);

  const { data: organisms, loading: organismsLoading } = useFetch("/organisms");
  const { data: ownedProjects, loading: ownedProjectsLoading } = useFetch("/projects/owned");
  const { data: dataTypes, loading: dataTypesLoading } = useFetch("/data/types");
  const { data: sampleTypes, loading: sampleTypesLoading } = useFetch("/samples/types");

  useDocumentTitle("Upload Data - Flow");

  if (organismsLoading || ownedProjectsLoading || dataTypesLoading || sampleTypesLoading) return <Base loading={true} />

  const tabs = ["Multiplexed", "Other Data"];
  if (organisms.length > 0) tabs.unshift("Demultiplexed");
  if (process.env.REACT_APP_USE_SYSTEM_GROUPS === "true") tabs.push("Import Local Data");
  const tabToUse = tab || tabs[0];

  return (
    <Base>
      <h1>Upload Data</h1>
      <Tabs labels={tabs} selected={tabToUse} setSelected={setTab} />
      {tabToUse === "Multiplexed" && (
        <div className="flex flex-wrap gap-x-36 gap-y-16">
          <AnnotationUploader sampleTypes={sampleTypes} />
          <MultiplexedUploader />
        </div>
      )}
      {tabToUse === "Demultiplexed" && organisms.length > 0 && (
        <DemultiplexUploader
          projects={ownedProjects.projects}
          organisms={organisms}
          sampleTypes={sampleTypes}
        />
      )}
      {tabToUse === "Other Data" && (
        <div>
          <DataUploader dataTypes={dataTypes} />
        </div>
      )}
      {tabToUse === "Import Local Data" && (
        <div>
          <DataImporter />
        </div>
      )}
    </Base>
  );
};

UploadPage.propTypes = {
  
};

export default UploadPage;