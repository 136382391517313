import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import UserImage from "../components/UserImage";
import ProjectsGrid2 from "../components/ProjectsGrid2";
import SampleTable2 from "../components/SamplesTable2";
import { useDocumentTitle, useFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import Base from "./Base";
import moment from "moment";
import Tabs from "../components/Tabs";

const UserPage = () => {

  const { username } = useParams();

  const [tab, setTab] = useState("Groups");

  const [projectsPage, setProjectsPage] = useState(1);
  const [projectsCount, setProjectsCount] = useState(null);
  const [projects, setProjects] = useState(null);
  const [projectsFilter, setProjectsFilter] = useState(null);
  const [hasProjects, setHasProjects] = useState(null);
  const projectsPageSize = 20;

  const [samplesPage, setSamplesPage] = useState(1);
  const [samplesCount, setSamplesCount] = useState(null);
  const [samples, setSamples] = useState(null);
  const [samplesFilter, setSamplesFilter] = useState(null);
  const [hasSamples, setHasSamples] = useState(null);
  const samplesPageSize = 12;

  const { loading, data: user } = useFetch(`/users/${username}`);

  const { loading: projectsLoading } = useFetch(`/users/${username}/projects`, {
    params: {page: projectsPage, count: projectsPageSize, filter: projectsFilter},
    onCompleted: data => {
      setProjectsCount(data.count);
      setProjects(data.projects);
      if (hasProjects === null) setHasProjects(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / projectsPageSize);
      if (projectsPage > maxPossiblePage) setProjectsPage(maxPossiblePage || 1);
    }
  })

  const { loading: samplesLoading } = useFetch(`/users/${username}/samples`, {
    params: {page: samplesPage, count: samplesPageSize, filter: samplesFilter},
    onCompleted: data => {
      setSamplesCount(data.count);
      setSamples(data.samples);
      if (hasSamples === null) setHasSamples(!!data.count);
      const maxPossiblePage = Math.ceil(data.count / samplesPageSize);
      if (samplesPage > maxPossiblePage) setSamplesPage(maxPossiblePage || 1);
    }
  })

  useDocumentTitle(user ? `${user.name} - Flow` : "Flow");

  if (loading) return <Base loading={true} />

  if (!user) return <Base notFound={true} />

  return (
    <Base>
      <h1>{user.name}</h1>
      <div className="flex items-center gap-2 my-6 sm:gap-4">
        <UserImage user={user} className="w-16 h-16 sm:w-24 sm:h-24" />
        <div>
          <div className="text-lg font-medium text-[#3B59C3] sm:mb-0.5 sm:text-2xl">{user.username}</div>
          <div className="text-sm text-[#595959] sm:text-base">Joined <time>{moment(user.created * 1000).format("Do MMMM YYYY")}</time></div>
        </div>
      </div>
      <Tabs labels={["Groups", "Projects", "Samples"]} selected={tab} setSelected={setTab} />
      {tab === "Groups" && (
        <div className="flex flex-wrap gap-y-6 gap-x-12">
          {user.groups.map(group => (
            <Link className="hover:no-underline max-w-md" to={`/groups/@${group.slug}/`} key={group.slug}>
              <div className="flex gap-2 font-medium">
                <div>{group.name}</div>
                <div className="text-[#3B59C3]">@{group.slug}</div>
              </div>
              <div className="text-sm">{group.description}</div>
            </Link>
          ))}
          {user.groups.length === 0 && (
            <div>User is not a member of any groups.</div>
          )}
        </div>
      )}
      {tab === "Projects" && (
        <>
          {!projectsLoading && !hasProjects && (
            <div className="text-sm mb-6 info sm:mb-8">
              This user has no public projects.
            </div>
          )}
          {projectsLoading && !hasProjects && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasProjects && (
            <ProjectsGrid2
              projects={projects}
              page={projectsPage}
              pageSize={projectsPageSize}
              setPage={setProjectsPage}
              totalCount={projectsCount}
              loading={projectsLoading}
              onFilter={s => setProjectsFilter(s || null)}
              noMessage="No projects match that term." 
            />
          )}
        </>
      )}
      {tab === "Samples" && (
        <>
          {!samplesLoading && !hasSamples && (
            <div className="text-sm mb-6 info sm:mb-8">
              This user has no public samples.
            </div>
          )}
          {samplesLoading && !hasSamples && (
            <div className="w-full h-48 flex justify-center items-center">
              <ClipLoader size={100} />
            </div>
          )}
          {hasSamples && (
            <SampleTable2
              samples={samples}
              page={samplesPage}
              pageSize={samplesPageSize}
              setPage={setSamplesPage}
              totalCount={samplesCount}
              loading={samplesLoading}
              onFilter={s => setSamplesFilter(s || null)}
              noMessage="No samples match that term." 
            />
          )}
        </>
      )}
    </Base>
  );
};

UserPage.propTypes = {
  
};

export default UserPage;