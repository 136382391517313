import PropTypes from "prop-types";
import Selector from "./Selector";
import ExecutionSelector2 from "./ExecutionSelector2";

const PipelineSectionFiller = props => {

  const {
    section, filesets, upstreamPipelineVersions, filesetId, setFilesetId,
    dataParams, setDataParams, lockedKeys, setLockedKeys,
    executionPrep, setExecutionPrep, organism, setOrganism,
    inputClass, placeholderClass, selectOptionClass, selectOptionsClass
  } = props;

  const takesFileset = Boolean(filesets) && section.from_fileset;
  const takesExecution = upstreamPipelineVersions.length > 0 &&  section.from_execution;

  if (!takesFileset && !takesExecution) return null;

  const filesetSelected = filesetId => {
    if (filesetId === "-") {
      setFilesetId(null);
      const newDataParams = {...dataParams};
      for (const key of lockedKeys) {
        delete newDataParams[key];
      }
      setLockedKeys([]);
      setDataParams(newDataParams);
      return;
    }
    setFilesetId(filesetId);
    const fileset = filesets.find(f => f.id === filesetId);
    const newDataParams = {...dataParams}; const newLockedKeys = [];
    for (const [key, input] of Object.entries(section.params)) {
      if (input.fileset_pattern) {
        const regex = new RegExp(input.fileset_pattern);
        const data = fileset.data.find(d => regex.test(d.filename));
        if (data) {
          newDataParams[key] = data;
          newLockedKeys.push(key);
        }
      }
    }
    setDataParams(newDataParams);
    setLockedKeys(newLockedKeys);
  }

  const executionPrepSelected = execution => {
    setExecutionPrep(execution);
    if (!execution) {
      const newDataParams = {...dataParams};
      for (const key of lockedKeys) {
        delete newDataParams[key];
      }
      setLockedKeys([]);
      setDataParams(newDataParams);
      setOrganism(null);
      setFilesetId(null);
      return;
    }
    const newDataParams = {...dataParams};
    const newLockedKeys = [];
    for (const [key, input] of Object.entries(section.params)) {
      if (input.execution_output?.process) {
        const data = execution.downstream_data?.find(d => {
          const processNames = d.process_execution_name.split(":");
          const matchNames = input.execution_output.process.split(":");
          const relevantProcessNames = processNames.slice(-(matchNames.length));
          const processesMatch = JSON.stringify(matchNames) === JSON.stringify(relevantProcessNames);
          const regex = new RegExp(input.execution_output.pattern);
          const filetypesMatch = regex.test(d.filename);
          return processesMatch && filetypesMatch;
        })
        if (data) {
          newDataParams[key] = data;
          newLockedKeys.push(key);
        }
      } else if (input.execution_output && !input.execution_output.process && execution) {
        const regex = new RegExp(input.execution_output.pattern);
        const data = execution.inputs.find(d => regex.test(d.filename));
        if (data) {
          newDataParams[key] = data;
          newLockedKeys.push(key);
        }
      }
    }
    setFilesetId(execution.fileset?.id);
    setDataParams(newDataParams);
    setLockedKeys(newLockedKeys);
    setOrganism(execution.fileset?.organism);
  }

  return (
    <div className="info border-l-8 pl-4 max-w-3xl">
      <div className="font-medium mb-2 text-sm text-[#37474F]">
        {takesFileset && "You can populate this section using a pre-defined fileset:"}
        {takesExecution && `These parameters can be supplied from an existing Flow execution. Select execution files below. ${organism ? `Must be ${organism.name} to match the samples picked.` : ""}`}
      </div>
      {takesFileset && (
        <Selector
          value={filesetId || "-"}
          options={filesets ? [{id: "-", label: "---"}, ...filesets.map(f => ({id: f.id, label: `${f.organism_name} ${f.name}`}))] : []}
          onChange={filesetSelected}
          inputClassName={`${inputClass} cursor-pointer max-w-sm`}
          placeholder="Select a fileset"
          optionClassName={selectOptionClass}
          optionsClassName={selectOptionsClass}
        />
      )}
      {takesExecution && (
        <ExecutionSelector2
          inputClass={inputClass}
          pipelineVersions={upstreamPipelineVersions}
          returnData={true}
          returnInputs={true}
          organismId={organism?.id}
          placeholder="Select an execution..."
          placeholderClass={placeholderClass}
          execution={executionPrep}
          setExecution={executionPrepSelected}
        />
      )}
    </div>
  );
};

PipelineSectionFiller.propTypes = {
  section: PropTypes.object.isRequired,
  filesets: PropTypes.array,
  upstreamPipelineVersions: PropTypes.array,
  filesetId: PropTypes.string,
  setFilesetId: PropTypes.func.isRequired,
  dataParams: PropTypes.object.isRequired,
  setDataParams: PropTypes.func.isRequired,
  lockedKeys: PropTypes.array.isRequired,
  setLockedKeys: PropTypes.func.isRequired,
  executionPrep: PropTypes.object,
  setExecutionPrep: PropTypes.func.isRequired,
  organism: PropTypes.object,
  setOrganism: PropTypes.func.isRequired,
  inputClass: PropTypes.string.isRequired,
  placeholderClass: PropTypes.string.isRequired,
  selectOptionClass: PropTypes.string.isRequired,
  selectOptionsClass: PropTypes.string.isRequired,
};

export default PipelineSectionFiller;