import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import FileInput from "./FileInput";
import UploadButton from "./UploadButton";
import { useLazyFetch } from "../hooks";
import { upload } from "../upload";
import { data } from "autoprefixer";
import DataSelector2 from "./DataSelector2";

const DataUploader2 = props => {

  const { dataTypes } = props;

  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState(null);
  const [dataIds, setDataIds] = useState({});
  const [dataType, setDataType] = useState(null);
  const [pairedData, setPairedData] = useState(null);
  const [error, setError] = useState("");
  const canceled = useRef(false);

  const [,uploadData] = useLazyFetch("/upload", {method: "POST", form: true});

  const sendChunk = async (filename, chunk, expectedFileSize, isLast, previous, extra) => {
    const params = {
      blob: chunk, is_last: isLast, expected_file_size: expectedFileSize,
      data_type: dataType || "", pair1: pairedData?.id || null,
      data: previous?.data?.id || "", filename, ...extra
    }
    return await uploadData({params});
  }

  const onNetworkError = () => {
    setError("There was a network error while uploading the data.");
  }

  const onError = () => {
    setError("There was a problem uploading the data.");
  }

  const upload_ = async () => {
    return upload(files, 1_000_000, setProgress, setDataIds, canceled, sendChunk, onNetworkError, onError);
  }

  const cancel = () => {
    setError("");
    setProgress(null);
    setDataIds({});
    setFiles([]);
    canceled.current = true;
  }

  const headingClass = "text-base font-medium mb-1 md:text-lg";
  const canCancel = progress && Object.values(progress).length > 0 && !error && Object.values(progress).some(p => p < 1);
  
  return (
    <div className="max-w-2xl">
      <FileInput
        files={files}
        setFiles={setFiles}
        multiple={true}
        allowDirectories={true}
        progress={progress}
        setProgress={setProgress}
        dataIds={dataIds}
        setDataIds={setDataIds}
        error={error}
        setError={setError}
        className="mb-8"
      />
      <div className="flex flex-wrap gap-x-6 gap-y-2">
        {dataTypes.length > 0 && (
          <div>
            <div className={headingClass}>Data type</div>
            <select
              value={dataType || ""}
              onChange={e => setDataType(e.target.value)}
              className="border border-[#A5ACC6] outline-none h-9 rounded cursor-pointer mb-1.5 py-1.5 text-[#54618D] font-medium px-3 text-sm w-full max-w-xl md:text-base md:w-fit bg-[#F9F9F9] placeholder-text-[#777F9B]"
            >
              <option value="">Default</option>
              {dataTypes.map((dataType, i) => (
                <option key={i} value={dataType.id}>{dataType.name}</option>
              ))}
            </select>
          </div>
        )}
        <div className="w-full max-w-sm">
          <div className={headingClass}>Pair with existing data</div>
          <DataSelector2
            inputClass="border border-[#A5ACC6] outline-none h-9 rounded cursor-pointer mb-1.5 py-1.5 text-[#54618D] font-medium px-3 text-sm md:text-base w-full max-w-xl bg-[#F9F9F9] placeholder-text-[#777F9B]"
            category={1}
            data={pairedData}
            isSingle={true}
            setData={setPairedData}
            multiple={false}
            className="w-full max-w-xl"
            placeholderClass="text-[#54618D] text-sm md:text-base"
            placeholder="Select data to pair with"
          />
        </div>
      </div>
      <UploadButton files={files} onClick={upload_} cancel={canCancel ? cancel : null} />
    </div>
  );
};

DataUploader2.propTypes = {
  dataTypes: PropTypes.array.isRequired,
};

export default DataUploader2;