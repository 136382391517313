import { useState } from "react";
import PropTypes from "prop-types";
import Button from "../components/Button";
import { useLazyFetch } from "../hooks";

const DataTypeEditor = props => {

  const { data, setData, dataTypes } = props;

  // 0 means hasn't been set, null means has null data type
  const [value, setValue] = useState(0);

  const isDifferent = value !== 0 && value !== data.data_type;

  const [{loading}, updateDataType] = useLazyFetch(`/data/${data.id}/edit`, {
    method: "POST",
    params: {data_type: value},
    onCompleted: data => {
      setData(data);
      setValue(0);
    }
  }) 

  return (
    <div className="edit-section">
      <h2 className="edit-heading">Data Type</h2>

      <div className="flex">
        <select
          value={value === 0 ? (data.data_type || "") : (value || "")}
          onChange={e => setValue(e.target.value || null)}
          className="border border-[#A5ACC6] outline-none h-9 rounded-l mb-1.5 py-1.5 text-[#54618D] font-medium px-3 text-sm md:text-base w-full md:w-fit bg-[#F9F9F9] placeholder-text-[#777F9B]"
        >
          <option value="">No data type</option>
          {dataTypes.map(type => (
            <option
              key={type.id}
              value={type.id}
            >
              {type.name}
            </option>
          ))}
        </select>
        <Button
          className={`btn-primary h-9 rounded-l-none ${(loading || !isDifferent) ? "opacity-50 pointer-events-none" : ""}`}
          loading={loading}
          onClick={updateDataType}
        >
          Update
        </Button>
      </div>
      
    </div>
  );
};

DataTypeEditor.propTypes = {
  data: PropTypes.object.isRequired,
  dataTypes: PropTypes.array.isRequired,
  setData: PropTypes.func.isRequired
};

export default DataTypeEditor;