import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const SampleTypeChart = props => {

  const { sampleCounts } = props;

  const typesData = (sampleCounts || {}).types || {};

  const data = Object.entries(typesData);

  const series = [{data: data}]

  const options = {
    chart: {
      zoomType: "x", backgroundColor: null, type: "column",
      style: {fontFamily: "Basier Circle"},
    },
    title: null,
    legend: {enabled: false},
    credits: {enabled: false},
    tooltip: {pointFormat: "<b>{point.y}</b> samples<br/>"},
    xAxis: {
      categories: data.map(d => d[0]),
      labels: {style: {color: "#37474F", fontWeight: "500"}}
    },
    yAxis: {
      title: {
        enabled: true, text: "Number of samples",
        style: {color: "#37474F", fontWeight: "500"}
      },
    },
    plotOptions: {column: {colorByPoint: true}},
    colors: ["#3B59C3", "#7A6ADB", "#1BB8D9"],
    series
  };

  return (
    <div className="w-full">
      <div className="font-medium mx-auto w-fit mb-2">Public samples by type</div>
      <div className="h-72">
        <HighchartsReact
          highcharts={Highcharts} options={options}
          containerProps={{style: {height: "100%"}}}
        />
      </div>
    </div>
  );
};

SampleTypeChart.propTypes = {
  sampleCounts: PropTypes.object
};

export default SampleTypeChart;