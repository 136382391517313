import { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLazyFetch } from "../hooks";
import { ClipLoader } from "react-spinners";
import AnnotationSheetLinks from "./AnnotationSheetLinks";

const MetadataImporter = props => {

  const { samples, setSamples, sampleTypes, disabled } = props;

  const [hovering, setHovering] = useState(false);
  const ref = useRef();
  const labelRef = useRef();

  const [{ loading }, upload] = useLazyFetch("/upload/sample/metadata", {
    method: "POST",
    form: true,
  });

  const dragEnter = e => {
    e.preventDefault();
    setHovering(true);
  }

  const dragLeave = e => {
    e.preventDefault();
    setHovering(false);
  }

  const dragOver = e => {
    e.preventDefault();
  }

  const drop = e => {
    e.preventDefault();
    onFileChange({target: {files: e.dataTransfer.files}});
  }

  useEffect(() => {
    if (labelRef.current) {
      labelRef.current.addEventListener("dragleave", dragLeave, false);
      labelRef.current.addEventListener("dragenter", dragEnter, false);
      labelRef.current.addEventListener("dragover", dragOver, false);
      labelRef.current.addEventListener("drop", drop, false);
    }
  })

  const onFileChange = e => {
    const file = e.target.files[0];
    setHovering(false);
    ref.current.value = "";
    ref.current.files = null;
    upload({
      params: { file },
      onCompleted: data => {
        const values = data.data;
        const filenameKeys = [...Object.keys(values)].some(v => v.includes("."));
        for (let s = 0; s < samples.length; s++) {
          const sample = samples[s];
          const incomingValues = filenameKeys ? values[sample.file1.name] : values[s];
          if (!incomingValues) continue;
          samples[s] = {...samples[s], ...incomingValues};
        }
        setSamples([...samples]);
      }
    });
  }

  return (
    <div className={`flex gap-x-4 gap-y-2 items-start flex-col md:flex-row ${props.className || ""}`}>
      <label
        ref={labelRef}
        htmlFor="annotationInput"
        className={`block border-4 border-[#3B59C3] border-dashed border-opacity-50 hover:border-opacity-80 rounded py-2 px-3 max-w-sm cursor-pointer ${disabled ? "opacity-50 pointer-events-none" : ""} ${hovering ? "border-opacity-80" : ""}`}
      >
        <div className="text-lg mb-0.5 font-medium pointer-events-none">
          Auto-fill from annotation sheet
          {loading && <ClipLoader size={16} className="inline-block ml-2 -mb-0.5" speedMultiplier={1.5} />}
        </div>
        <div className="text-xs pb-1 pointer-events-none">
          You can auto-populate the metadata by selecting or dragging a Flow annotation sheet here.
          Add an extra 'File' column to link file names to the rest of the annotation.
        </div>
        <input type="file" accept=".csv,.xlsx" className="hidden" id="annotationInput" onChange={onFileChange} ref={ref} />
      </label>
      <AnnotationSheetLinks sampleTypes={sampleTypes} file={true} />
    </div>
  );
};

MetadataImporter.propTypes = {
  samples: PropTypes.array.isRequired,
  setSamples: PropTypes.func.isRequired,
  sampleTypes: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};

export default MetadataImporter;