import { useState } from "react";
import PropTypes from "prop-types";
import SampleRow2 from "./SampleRow2";
import Paginator from "./Paginator2";
import { ClipLoader } from "react-spinners";
import TableFilter from "./TableFilter";
import BulkDeleteButton from "./BulkDeleteButton2";
import BulkDownloadButton2 from "./BulkDownloadButton2";

const SampleTable2 = props => {

  const { samples, page, setPage, pageSize, totalCount, onFilter, loading, noMessage, onBulkDelete, showSource, showPurificationTarget, showHeader } = props;

  const [selectedIds, setSelectedIds] = useState([]);

  const samplesChecked = d => {
    if (selectedIds.includes(d.id)) {
      setSelectedIds(selectedIds.filter(id => id !== d.id))
    } else {
      setSelectedIds([...selectedIds, d.id])
    }
  }

  const selectedOnPage = samples.filter(d => selectedIds.includes(d.id));
  const anySelected = selectedOnPage.length > 0;

  const totalPages = Math.ceil(totalCount / pageSize);

  const showScientist = samples && samples.some(s => s.scientist && s.scientist !== s.owner_name);

  const thClass = "font-medium text-sm text-[#777777] bg-[#E7E9F0] bg-opacity-80 whitespace-nowrap py-1.5 text-left px-4";

  return (
    <div className="relative" style={{minHeight: 250}}>
      <div className="flex flex-wrap gap-2 justify-between items-center mb-3">
        <div className="flex flex-wrap gap-2 items-center">
          <TableFilter filter={onFilter} />
          {totalPages > 1 && (
            <Paginator
              currentPage={page} totalPages={totalPages} onChange={setPage}
            />
          )}
        </div>
        {totalCount > 0 && (
          <div className="flex flex-wrap gap-2 items-center">
            {samples.length > 0 && (
              <div
                className="text-xs link w-fit"
                onClick={() => setSelectedIds(anySelected ? [] : [...selectedIds, ...samples.map(d => d.id)])}
              >
                {anySelected ? "Deselect All" : "Select All"}
              </div>
            )}
            <div className="flex flex-wrap gap-2 items-center">
              <BulkDownloadButton2 selectedIds={selectedIds} isSamples={true} />
              {onBulkDelete && (
                <BulkDeleteButton
                  selectedIds={selectedIds}
                  onDelete={onBulkDelete}
                  setSelectedIds={setSelectedIds}
                  isSamples={true}
                />
              )}
            </div>
          </div>
        )}
      </div>
      {samples.length === 0 && !loading && (
        <div className="mt-8 italic text-sm">
          <span className="info border-l-red-200">{noMessage}</span>
        </div>
      )}
      {loading && (
        <div className="absolute z-40 h-48 w-full flex justify-center items-center">
          <ClipLoader size={100} speedMultiplier={2} />
        </div> 
      )}
      <div className="overflow-y-auto rounded shadow w-full relative">
        <table className={`border-collapse w-full border-0 overflow-hidden ${loading ? "opacity-50 pointer-events-none" : ""}`}>
          {showHeader && samples && samples.length > 0 &&(
            <thead>
              <tr>
                <th className={thClass} />
                {samples && samples.some(s => "private" in s) && <th className={thClass}>Privacy</th>}
                <th className={thClass}>Name</th>
                <th className={thClass}>Type</th>
                <th className={thClass}>Created</th>
                {showScientist && <th className={thClass}>Scientist</th>}
                {samples && samples.some(s => s.owner_name) && <th className={thClass}>Owner</th>}
                <th className={thClass}>Organism</th>
                {showSource && <th className={thClass}>Source</th>}
                {showPurificationTarget && <th className={thClass}>Purification Target</th>}
                <th className={thClass}>Project</th>
              </tr>
            </thead> 
          )}
          <tbody>
            {samples.map((ex, index) => (
              <SampleRow2
                sample={ex} key={ex.id} index={index}
                checked={selectedIds.includes(ex.id)}
                onCheck={() => samplesChecked(ex)}
                showSource={showSource}
                showPurificationTarget={showPurificationTarget}
                showScientist={showScientist}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

SampleTable2.propTypes = {
  samples: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  pageSize: PropTypes.number.isRequired,
  totalCount: PropTypes.number.isRequired,
  onFilter: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  noMessage: PropTypes.string.isRequired,
  onBulkDelete: PropTypes.func,
  showSource: PropTypes.bool,
  showPurificationTarget: PropTypes.bool,
  showHeader: PropTypes.bool
};

export default SampleTable2;